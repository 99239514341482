export const decodeGasStationServices = (bitmask) => ({
  atm: (bitmask & 1) === 1,
  convenienceStore: (bitmask & 2) === 2,
  restaurant: (bitmask & 4) === 4,
  carWash: (bitmask & 8) === 8,
  tireShop: (bitmask & 16) === 16,
  oilChange: (bitmask & 32) === 32,
  autoMechanic: (bitmask & 64) === 64,
});

export const decodeFuelTypes = (bitmask) => ({
  gcc: (bitmask & 1) === 1,
  gca: (bitmask & 2) === 2,
  gcp: (bitmask & 4) === 4,
  s500c: (bitmask & 8) === 8,
  s500a: (bitmask & 16) === 16,
  s10c: (bitmask & 32) === 32,
  s10a: (bitmask & 64) === 64,
  ehc: (bitmask & 128) === 128,
  eha: (bitmask & 256) === 256,
  ehcp: (bitmask & 512) === 512,
});
