import React from "react";
import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import './SimpleFooter.css'; // Import custom CSS

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer ini">
          <Container>
            <Row className="row-grid align-items-center">
              <Col>
                <h5 className="text-white text-center font-weight-bold mb-1">
                  ENTRE EM CONTATO
                </h5>
                <p className="text-white text-center footersmall contact-info">
                  Av. Prof. Francisco Degni, 55 - Jd. Quitandinha - Araraquara/SP
                </p>
                <p className="text-white text-center footersmall contact-info">
                  <a href="tel:+551633019666" className="text-white"> (16) 3301-9666 </a>
                  {' '}|{' '}<a href="tel:+5516996200198" className="text-white"> (16) 99620-0198 </a>
                </p>
                <p className="text-white text-center footersmall contact-info">
                  <a href="mailto:negocios.cempeqc@gmail.com" className="text-white"> negocios.cempeqc@gmail.com </a>
                </p>
              </Col>
              <Col className="text-center btn-wrapper" lg="6">
                <a href="https://www.instagram.com/cempeqc/" id="tooltipInstagram" target="_blank" rel="noopener noreferrer" style={iconStyle}>
                  <FaInstagram />
                </a>
                <UncontrolledTooltip delay={0} target="tooltipInstagram">Nos siga no Instagram!</UncontrolledTooltip>
                <a href="https://wa.me/5516996200198" id="tooltipWhatsapp" target="_blank" rel="noopener noreferrer" style={iconStyle}>
                  <FaWhatsapp />
                </a>
                <UncontrolledTooltip delay={0} target="tooltipWhatsapp">Fale conosco no Whatsapp!</UncontrolledTooltip>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="text-center">
                <small className="text-muted">&copy; {new Date().getFullYear()} Cempeqc. Todos os direitos reservados.</small>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

const iconStyle = {
  fontSize: "2rem", // Ajustar o tamanho dos ícones
  color: "#fff", // Cor branca para os ícones
  margin: "0 0.5rem", // Adicionar alguma margem para melhor espaçamento
  transition: "color 0.3s", // Transição suave para o efeito hover
  display: "inline-block" // Garantir que os ícones sejam exibidos inline
};

export default SimpleFooter;
