import React from "react";
import { Link } from "react-router-dom";
import * as bitmaskDecoder from "../../utils/bitmaskDecode"
import {parseISOToStringTime, parseISOToStringDate} from "../../utils/ParseDates"
import { DEFAULT_LOGO, LOGO_BASE_URL } from "../../contants/gasStationsConstants"

import gasStationJson from "./gasStationListJson.json"

// reactstrap components
import {
  Button,
  ListGroup,
  ListGroupItem,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import SimpleFooter from "components/Footers/SimpleFooter.js";

class GasStationList extends React.Component {
  state = {
    gasStations: [],
    gasStation: []
  };



  componentDidMount = () =>  {
    const searchWord = this.props.location.state.searchWord
    const gasStations = []
    gasStationJson.stations.filter(
      station => (
        station.city.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").match(searchWord) ||
        station.name.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").match(searchWord)
        )
    ).forEach(gasStation => {
      gasStations.push({
        id: gasStation.id,
        name: gasStation.name,
        latitude: gasStation.latitude,
        longitude: gasStation.longitude,
        isActive: gasStation.is_active,
        address: gasStation.address,
        city: gasStation.city,
        uf: gasStation.uf,
        services: bitmaskDecoder.decodeGasStationServices(gasStation.station_mask_value),
        fuelTypes: bitmaskDecoder.decodeFuelTypes(gasStation.fuel_category_value),
        businessDayOpenAt: parseISOToStringTime(gasStation.business_day_open_at),
        businessDayCloseAt: parseISOToStringTime(gasStation.business_day_close_at),
        holydayOpenAt: parseISOToStringTime(gasStation.holyday_open_at),
        holydayCloseAt: parseISOToStringTime(gasStation.holyday_close_at),
        lastGasolineSample: parseISOToStringDate(gasStation.last_gasoline_sample),
        lastAlcoholSample: parseISOToStringDate(gasStation.last_alcohol_sample),
        lastDieselSample: parseISOToStringDate(gasStation.last_diesel_sample),
        logo: `${LOGO_BASE_URL}${gasStation.avatar_thumb ? gasStation.avatar_thumb : DEFAULT_LOGO}`,
      })
    }
  )
    this.setState({ gasStations})
  }

  handleChildOver(gasStationEvento) {
    const gasStation = gasStationEvento;
    this.setState({ gasStation })
}

  handleChildClick(gasStationEvento) {
    const gasStation = gasStationEvento;
    this.setState({ gasStation })
    // const { history } = this.props;
    // history.push({pathname:'/GasStationDetails', state:this.state})
 }
  
  render() {
    return (
      <>
        <main ref="main">
          <section className="section ini">
            <Container className="py-md">
              <Row className="row-grid justify-content-center">
                <Col sm="4" xs="4" lg="5" className="mt-lg-5">
                  <Link to="/Mainpage">
                    <img
                      alt="..."
                      className="img-fluid rounded-circle"
                      src={require("assets/logo-cempeqc-combustivel-analisado.png")}
                      style={{ width: "350px" }}
                    />
                  </Link>
                </Col>
                <Col lg="7" className="align-items-center">
                  <Link to={{
                            pathname: 'GasStationDetails',
                            state: this.state
                          }} >
                    <ListGroup flush  >
                    {
                        this.state.gasStations
                          .map(
                            gasStation => (
                             
                              <ListGroupItem  
                              onMouseOver={() => this.handleChildClick(gasStation)}
                              onTouchEnd={() => this.handleChildClick(gasStation)}
                              onClick={()=> this.handleChildClick(gasStation)}
                              href='' tag="a" 
                              key={gasStation.id}>
                                <div className="small" >
                                 {gasStation.name}
                                  <div className="color-6" >
                                    {gasStation.address}
                                  </div>
                                  <div className="color-6" >
                                    {gasStation.city} - {gasStation.uf}
                                  </div>
                                </div>
                                <div className="info">
                                  <i className="fa fa-info"></i>
                                </div>
                              </ListGroupItem>
                            )
                          )
                      }
                    </ListGroup>
                  </Link>
                  <div className="col-lg-12 mb-5">
                    <Link to="/Mainpage">
                      <Button
                        color="default"
                        type="button"
                        className="btn border btn-default btn-block">
                        NOVA BUSCA
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <SimpleFooter />
        </main>
      </>
    );
  };
}

export default GasStationList;
