// src/index.js

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";
import "index.css"; // Importando o arquivo CSS global

import Index from "views/Index.js";
import MainPage from "views/IndexSections/MainPage.js";
import Program from "views/IndexSections/Program.js";
import GasStationList from "views/IndexSections/GasStationList.js";
import GasStationDetails from "views/IndexSections/GasStationDetails.js";
import Maintenance from "views/IndexSections/Maintenance.js";

import '@fortawesome/fontawesome-free/css/all.min.css';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={(props) => <Index {...props} />} />
      <Route
        path="/maintenance"
        exact
        render={(props) => <Maintenance {...props} />}
      />
      <Route
        path="/mainpage"
        exact
        render={(props) => <MainPage {...props} />}
      />
      <Route
        path="/program"
        exact
        render={(props) => <Program {...props} />}
      />
      <Route
        path="/gasstationlist"
        exact
        render={(props) => <GasStationList {...props} />}
      />
      <Route
        path="/gasstationdetails"
        exact
        render={(props) => <GasStationDetails {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
);
