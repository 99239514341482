import React from "react";
import { Container, Row, Col } from "reactstrap";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Maintenance extends React.Component {
  state = {};
  render() {
    return (
      <>
        <section className="section ini">
          <Container className="py-md">
            <Row className="row-grid justify-content-center align-items-center">
              <Col sm="6" xs="6" lg="5">
                <img
                  alt="..."
                  className="img-fluid rounded-circle"
                  src={require("assets/logo-cempeqc-combustivel-analisado.png")}
                  style={{ width: "350px" }}
                />
              </Col>
              <Col lg="7" className="align-items-center">
                <hr />
                <h4 className="text-white text-center font-weight-bold">
                  SITE EM MANUTENÇÃO
                </h4>
                <hr />
              </Col>
            </Row>
          </Container>
        </section>
        <SimpleFooter />
      </>
    );
  }
}

export default Maintenance;
