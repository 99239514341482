
export const parseTimestampToStringDate = (timestamp) => {
  const date = (new Date(timestamp)).toISOString().substring(0, 10);

  const splitDate = date.split('-');
  const parsedDate = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;

  return parsedDate;
};

export const parseISOToStringDate = (isoString) => {
  if (!isoString) {
    return ' --- ';
  }

  const date = isoString.substring(0, 10);

  const splitDate = date.split('-');
  const parsedDate = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;

  return parsedDate;
};

export const parseTimestampToStringTime = (timestamp) => {
  let time = null;
  let splitTime = null;

  splitTime = time.split(':');
  const parsedTime = `${splitTime[0]}:${splitTime[1]}`;

  return parsedTime;
};

export const parseISOToStringTime = (isoString) => {
  if (!isoString) {
    return ' --- ';
  }

  const time = isoString.substring(11, 19);

  const splitTime = time.split(':');
  const parsedTime = `${splitTime[0]}:${
    splitTime[1]}`;

  return parsedTime;
};

export const parseStringToTimestamp = (date, time) => {
  if (date && time) {
    const splitDate = date.split('/');
    const splitTime = time.split(':');


    const parsedTimestamp = Date
      .parse(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]}T${splitTime[0]}:${splitTime[1]}:00Z`);
    const timestampToAdd = 10800000;


    return parsedTimestamp + timestampToAdd;
  }

  return 0;
};
