import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import SimpleFooter from "components/Footers/SimpleFooter.js";

class GasStationDetails extends React.Component {
  state = {
    gasStation: []
  };

  componentDidMount = () => {
    
    const gasStation = this.props.location.state;//history.location.state.gasStation;
    this.setState({ gasStation })

    console.log(this.props.location.state)

  }
  render() {
    return (
      <>
        <main ref="main">
          <section className="section ini">
            <Container className="py-md">
              <Row className="row-grid justify-content-center">
                <Col sm="4" xs="4" lg="5" className="mt-lg-5">
                  <Link to="/Mainpage">
                    <img
                      alt="..."
                      className="img-fluid rounded-circle"
                      src={require("assets/logo-cempeqc-combustivel-analisado.png")}
                      style={{ width: "350px" }}
                    />
                  </Link>
                </Col>
                <Col lg="7" className="align-items-center">
                  <div className="details-text">
                    <h6 className="text-white text-center font-weight-bold mt-2 mb-3">
                      {this.props.location.state.gasStation.name}
                    </h6>
                    <div className="text-center small color-6">
                      {this.props.location.state.gasStation.address}, {this.props.location.state.gasStation.city} - {this.props.location.state.gasStation.uf}
                    </div>
                    <div className="text-center small color-6">
                      De segunda à sábado das {this.props.location.state.gasStation.businessDayOpenAt} às {this.props.location.state.gasStation.businessDayCloseAt}
                    </div>
                    <div className="text-center small color-6">
                      Domingos e feriados das {this.props.location.state.gasStation.holydayOpenAt} às {this.props.location.state.gasStation.holydayCloseAt}
                    </div>
                  </div>
                  <div className="details-img mt-2 mb-3">
                    <img
                        alt="..."
                        className="img-fluid mr-1"
                        src={require("assets/lavajato.png")}
                        style={ this.props.location.state.gasStation.services.carWash ? { width: "42px" } : { display: 'none' }}
                        data-placement="top"
                        id="lavajato"
                      />
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="lavajato"
                      >
                        Lavajato
                      </UncontrolledTooltip>
                    
                      <img
                        alt="..."
                        className="img-fluid mr-1"
                        src={require("assets/conveniencia.png")}
                        style={  this.props.location.state.gasStation.services.convenienceStore ? { width: "42px" } : { display: 'none' }}
                        data-placement="top"
                        id="conveniencia"
                      />
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="conveniencia"
                      >
                        Conveniência 
                      </UncontrolledTooltip>

                      <img
                        alt="..."
                        className="img-fluid mr-1"
                        src={require("assets/mecanica.png")}
                        style={ this.props.location.state.gasStation.services.autoMechanic ? { width: "42px" } : { display: 'none' }}
                        data-placement="top"
                        id="mecanica"
                      />
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="mecanica"
                      >
                        Mecânica
                      </UncontrolledTooltip>

                      <img
                        alt="..."
                        className="img-fluid mr-1"
                        src={require("assets/trocaoleo.png")}
                        style={ this.props.location.state.gasStation.services.oilChange ? { width: "42px" } : { display: 'none' }}
                        data-placement="top"
                        id="trocaoleo"
                      />
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="trocaoleo"
                      >
                        Troca de Óleo
                      </UncontrolledTooltip>

                      <img
                        alt="..."
                        className="img-fluid mr-1"
                        src={require("assets/restaurante.png")}
                        style={ this.props.location.state.gasStation.services.restaurant ? { width: "42px" } : { display: 'none' }}
                        data-placement="top"
                        id="restaurante"
                      />
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="restaurante"
                      >
                        Restaurante
                      </UncontrolledTooltip>

                      <img
                        alt="..."
                        className="img-fluid mr-1"
                        src={require("assets/borracharia.png")}
                        style={ this.props.location.state.gasStation.services.tireShop ? { width: "42px" } : { display: 'none' }}
                        data-placement="top"
                        id="borracharia"
                      />
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="borracharia"
                      >
                        Borracharia
                      </UncontrolledTooltip>

                      <img
                        alt="..."
                        className="img-fluid mr-1"
                        src={require("assets/caixa.png")}
                        style={ this.props.location.state.gasStation.services.atm ? { width: "42px" } : { display: 'none' }}
                        data-placement="top"
                        id="caixa"
                      />
                      <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target="caixa"
                      >
                        Caixa eletrônico
                      </UncontrolledTooltip>

                  </div>
                  <div className="details-fuels">
                    <div className="fuels ethanol" style={ this.props.location.state.gasStation.fuelTypes.ehc || this.props.location.state.gasStation.fuelTypes.eha || this.props.location.state.gasStation.fuelTypes.ehcp ? { width: "auto" } : { display: 'none' }}>
                      <img
                          alt="..."
                          className="img-fluid mr-3"
                          src={require("assets/icon-etanol-ok.png")}
                          style={{ width: "42px" }}
                        />
                        <div className="ethanol">
                          <p className="font-weight-bold small"> Etanol
                          <span style={ this.props.location.state.gasStation.fuelTypes.ehc ? { width: "auto" } : { display: 'none' }}> / Comum</span>
                          <span style={ this.props.location.state.gasStation.fuelTypes.eha ? { width: "auto" } : { display: 'none' }}> / Aditivado</span>
                          <span style={ this.props.location.state.gasStation.fuelTypes.ehcp ? { width: "auto" } : { display: 'none' }}> / Premium</span>
                          </p>
                          <p className="small">
                            Última coleta: <span>{this.props.location.state.gasStation.lastAlcoholSample}</span>
                          </p>
                        </div>
                    </div>
                    <div className="fuels gas" style={ this.props.location.state.gasStation.fuelTypes.gcc || this.props.location.state.gasStation.fuelTypes.gca || this.props.location.state.gasStation.fuelTypes.gcp ? { width: "auto" } : { display: 'none' }}>
                      <img
                          alt="..."
                          className="img-fluid mr-3"
                          src={require("assets/icon-gasolina-ok.png")}
                          style={{ width: "42px" }}
                        />
                        <div className="gas">
                          <p className="font-weight-bold small"> Gasolina
                          <span style={ this.props.location.state.gasStation.fuelTypes.gcc ? { width: "auto" } : { display: 'none' }}> / Comum</span>
                          <span style={ this.props.location.state.gasStation.fuelTypes.gca ? { width: "auto" } : { display: 'none' }}> / Aditivada</span>
                          <span style={ this.props.location.state.gasStation.fuelTypes.gcp ? { width: "auto" } : { display: 'none' }}> / Premium</span>
                          </p>
                          <p className="small">
                            Última coleta: <span>{this.props.location.state.gasStation.lastGasolineSample}</span>
                          </p>
                        </div>
                    </div>
                    <div className="fuels diesel" style={ this.props.location.state.gasStation.fuelTypes.s500c || this.props.location.state.gasStation.fuelTypes.s500a || this.props.location.state.gasStation.fuelTypes.s10c || this.props.location.state.gasStation.fuelTypes.s10a ? { width: "auto" } : { display: 'none' }}>
                      <img
                          alt="..."
                          className="img-fluid mr-3"
                          src={require("assets/icon-diesel-ok.png")}
                          style={ { width: "42px" } }
                        />
                        <div className="diesel">
                          <p className="font-weight-bold small"> Diesel
                          <span style={ this.props.location.state.gasStation.fuelTypes.s10c ? { width: "auto" } : { display: 'none' }}> / S10 Comum</span>
                          <span style={ this.props.location.state.gasStation.fuelTypes.s10a ? { width: "auto" } : { display: 'none' }}> / S10 Aditivado</span>
                          <span style={ this.props.location.state.gasStation.fuelTypes.s500c ? { width: "auto" } : { display: 'none' }}> / S500 Comum</span>
                          <span style={ this.props.location.state.gasStation.fuelTypes.s500a ? { width: "auto" } : { display: 'none' }}> / S500 Aditivado</span>
                          </p>
                          <p className="small">
                            Última coleta: <span>{this.props.location.state.gasStation.lastDieselSample}</span>
                          </p>
                        </div>
                    </div>
                  </div>
                  <div className="details-text">
                    <h6 className="text-white text-center font-weight-bold mt-2 mb-3">
                    <p>
                      Estes combustíveis estão em conformidade com a legislação vigente da ANP.
                    </p>
                    </h6>
                    
                  </div>
                  <div className="col-lg-12 mb-5 mt-5">
                    <Link to="/Mainpage">
                      <Button
                        color="default"
                        type="button"
                        className="btn border btn-default btn-block">
                        NOVA BUSCA
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
        </section>
        <SimpleFooter />
        </main>
      </>
    );
  }
}

export default GasStationDetails;
