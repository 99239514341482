import React from "react";

// index page sections
import MainPage from "./IndexSections/MainPage.js";

class Index extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        {/* <DemoNavbar /> */}
        <main ref="main">
          <MainPage />
        </main>
      </>
    );
  }
}

export default Index;
