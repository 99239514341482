import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FormGroup, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col, Button } from "reactstrap";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import ProgramContent from "components/ProgramContent"; // Importar o novo componente

const MainPage = () => {
  const [searchWord, setSearchWord] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const history = useHistory();

  const onSetSearch = (value) => {
    setSearchWord(value.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""));
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      history.push({
        pathname: '/GasStationList',
        state: { searchWord }
      });
    }
  };

  const handleSearchClick = () => {
    history.push({
      pathname: '/GasStationList',
      state: { searchWord }
    });
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <section className="section ini">
        <Container className="py-md">
          <Row className="row-grid justify-content-center align-items-center">
            <Col sm="6" xs="6" lg="5" className="text-center" style={{ textAlign: 'left' }}>
              <img
                alt="..."
                className="img-fluid rounded-circle"
                src={require("assets/logo-cempeqc-combustivel-analisado.png")}
                style={{ width: "350px", marginLeft: 'auto', marginRight: 'auto' }}
              />
            </Col>
            <Col lg="7" className="align-items-center text-center">
              <hr />
              <div onClick={toggleExpand} style={{ cursor: 'pointer', display: 'inline-block' }}>
                <h4 className="text-white font-weight-bold d-inline">O QUE É O PROGRAMA</h4>
                <i className={`fa fa-chevron-${isExpanded ? 'up' : 'down'} ml-2`} style={{ color: 'white' }} />
              </div>
              {isExpanded && (
                <div className="text-white mt-3">
                  <ProgramContent /> {/* Exibir o conteúdo do programa */}
                  <Button color="primary" onClick={toggleExpand} className="mt-3">Voltar</Button>
                </div>
              )}
              <hr />
              <h6 className="text-white font-weight-bold mt-4 mb-2">BUSQUE SEU POSTO REVENDEDOR</h6>
              <p className="text-white small">Digite o município ou o nome do posto</p>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-4">
                  <Input
                    placeholder="Buscar"
                    type="text"
                    onChange={(e) => onSetSearch(e.target.value)}
                    onKeyPress={handleKeypress}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText onClick={handleSearchClick} style={{ cursor: 'pointer' }}>
                      <i className="fa fa-search" style={{ color: "ActiveBorder" }}></i>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </Container>
      </section>
      <SimpleFooter />
    </>
  );
};

export default MainPage;
