import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Program extends React.Component {
  render() {
    return (
      <>
        <main ref="main">
          <section className="section section-shaped">
            <div className="shape shape-style-1 shape-primary"></div>
            <Container className="py-md">
              <Row className="row-grid justify-content-center">
                <Col sm="4" xs="4" lg="5" className="mt-lg-5">
                  <Link to="/mainpage">
                    <img
                      alt="..."
                      className="img-fluid rounded-circle"
                      src={require("assets/logo-cempeqc-combustivel-analisado.png")}
                      style={{ width: "350px" }}
                    />
                  </Link>
                </Col>
                <Col lg="7" className="align-items-center">
                  <h6 className="text-white text-center font-weight-bold mb-3">
                    PROGRAMA COMBUSTÍVEL ANALISADO
                  </h6>
                  <p className="text-white">
                    O Analises Cempeqc é um programa de monitoramento da qualidade de combustíveis do CEMPEQC - Centro de Monitoramento e Pesquisa da Qualidade de Combustíveis, Biocombustíveis, Petróleo e Derivados, um laboratório acreditado pela Coordenação Geral de Acreditação do Inmetro para ensaios segundo a NBR ISO/IEC 17025, sob número CRL 0308. Situado no Instituto de Química da Unesp – Campus de Araraquara (IQ/CAr), o CEMPEQC desde 2001 tem monitorado a qualidade dos combustíveis comercializados no Estado de São Paulo, tendo uma longa trajetória de sucesso com base no bom atendimento e na qualidade rigorosa dos serviços que presta aos seus clientes e à comunidade.
                  </p>
                  <p className="text-white">
                    Neste programa, qualquer posto revendedor de combustível contrata as análises laboratoriais das amostras de combustíveis que são coletadas diretamente das bombas, sem aviso prévio e, após a execução das análises e obtenção dos resultados, as informações são atualizadas no site Analises.Cempeqc informando quais combustíveis estão conformes de acordo com a Resolução vigente da ANP indicado pela data da última coleta.
                  </p>
                  <p className="text-white">
                    Os proprietários de postos que tiverem interesse em participar do Programa podem entrar em contato pelo e-mail negocios.cempeqc@gmail.com ou pelo 16 99711-1877.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
          <SimpleFooter />
        </main>
      </>
    );
  }
}

export default Program;
